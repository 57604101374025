import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  DirectionsService,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { themeContext } from "../../../shared/store/contexts/context.theme";
import markerIcon from "../../../assets/images/markerIcon.svg";
import starIcon from "../../../assets/images/star.svg";
import { P, TextLink } from "../../atoms/Texts";
import { OutlineButton } from "../../atoms/Buttons";
import { toastError, toastWarning } from "../../../shared/helpers";
import {
  containerStyle,
  darkMapStyle,
  InfoWindowStyle,
  lightMapStyle,
} from "./map.styles";

const map_key = process.env.REACT_APP_MAP_KEY;

const DirectionsAsAService = ({
  destination,
  userCoords,
  travelMode,
  callback,
  onLoad,
  onUnmount,
  directionResponse,
  setDirectionResponse,
}) => {
  const [, setCount] = useState(0);

  useEffect(() => {
    setCount(0);
  }, [userCoords, destination]);

  return (
    <DirectionsService
      options={{
        destination: destination,
        origin: userCoords,
        travelMode: travelMode,
      }}
      // required
      callback={callback}
      // optional
      onLoad={(directionsService) => {
        console.log(
          "DirectionsService onLoad directionsService: ",
          directionsService,
        );
      }}
      // optional
      onUnmount={(directionsService) => {
        console.log(
          "DirectionsService onUnmount directionsService: ",
          directionsService,
        );
      }}
    />
  );
};

const DirectionsServiceMemo = React.memo(DirectionsAsAService);

const MapResult = ({ theme, mapInfo, setDestination }) => {
  const [more, setMore] = useState(false);

  return (
    <div
      className="w-full h-full z-50 inset-0 pb-4"
      style={{
        background: theme.background,
      }}
    >
      <div className="flex">
        <div className="w-10/12">
          <P
            fontFamily="medium"
            color={theme.purpleFont}
            className="mb-4"
            fontSize="16px"
          >
            {mapInfo.name}
          </P>
          <P fontSize="14px" color={theme.secondaryFont}>
            {`${mapInfo.locations?.[0]?.address}, ${mapInfo.locations?.[0]?.stateOrProvince} ${mapInfo.locations?.[0]?.postalCode} ${mapInfo.locations?.[0]?.country}.`}
          </P>
          <a href={`tel:${mapInfo.phones?.[0]?.number}`}>
            <P fontSize="14px" color={theme.secondaryFont}>
              {mapInfo.phones?.[0]?.number}
            </P>
          </a>
          <a
            href={
              mapInfo.email.includes("mailto")
                ? mapInfo.email
                : `mailto:${mapInfo.email}`
            }
          >
            <P
              fontSize="14px"
              color={theme.secondaryFont}
              style={{
                overflowWrap: "anywhere",
              }}
            >
              {mapInfo.email}
            </P>
          </a>

          {mapInfo.url && (
            <a href={mapInfo.url} target="_blank" rel="noopener noreferrer">
              <P color={theme.purpleFont} fontSize="16px" className="mt-2">
                {mapInfo.url}
              </P>
            </a>
          )}

          {mapInfo.classification && (
            <>
              <TextLink color={theme.primaryFont} fontFamily="semi" className="mt-8">
                Classification
              </TextLink>
              <P color={theme.lightFont} fontSize="16px" className="mt-2">
                {mapInfo.classification}
              </P>
            </>
          )}
          {/* <TextLink
            color={theme.lightPurple}
            fontFamily="italic"
            className="mt-1 mb-4"
          >
            Last updated {mapInfo?.lastUpdate}
          </TextLink> */}

          <div className="flex items-center">
            {/* <TextLink
              color={mapInfo.status === "Open" ? theme.greenFont : theme.redFont}
              fontFamily="medium"
              className="mr-2"
            >
              {mapInfo.status === "Open" ? "Open Now" : "Closed now"}
            </TextLink> */}
            {/* <Link to="">
              <TextLink
                color={theme.purpleFont}
                fontFamily="medium"
              >
                {
                  mapInfo.status === "Open"  ? "" : "See open hours"
                }
              </TextLink>
            </Link> */}
          </div>

          {more && (
            <>
              {mapInfo.description && (
                <>
                  <TextLink
                    color={theme.primaryFont}
                    fontFamily="semi"
                    className="mt-8"
                  >
                    Description
                  </TextLink>
                  <P
                    color={theme.lightFont}
                    fontSize="16px"
                    className="mt-2 md:w-1/2"
                  >
                    {mapInfo.description}
                  </P>
                </>
              )}

              <div className="flex flex-wrap items-center mt-6">
                {/* <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mr-6"
                  onClick={() => setMore(false)}
                >
                  Collapse
                </OutlineButton> */}

                <Link to={`/organization/details/${mapInfo.id}`}>
                  <OutlineButton
                    color={theme.buttonFont}
                    bg="transparent"
                    className="mr-6"
                    onClick={() => setMore(false)}
                  >
                    View
                  </OutlineButton>
                </Link>

                {/* <Link to={`/suggest/${mapInfo.id}`}>
                <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mt-6 mr-6"
                >
                  Suggest Edit
                </OutlineButton>
              </Link> */}
                {/* <PrimaryButton
                  color="#fff"
                  className="mt-6"
                  style={{
                    padding: "10px 20px",
                  }}
                  onClick={() =>
                    setDestination({
                      lat: Number(mapInfo.locations?.[0]?.latitude),
                      lng: Number(mapInfo.locations?.[0]?.longitude),
                    })
                  }
                >
                  Get Direction
                </PrimaryButton> */}
              </div>
            </>
          )}

          {!more && (
            <>
              <div className="flex flex-wrap items-center mt-6">
                {/* <OutlineButton
                  color={theme.buttonFont}
                  bg="transparent"
                  className="mr-6"
                  onClick={() => setMore(true)}
                >
                  Expand
                </OutlineButton> */}

                <Link to={`/organization/details/${mapInfo.id}`}>
                  <OutlineButton
                    color={theme.buttonFont}
                    bg="transparent"
                    className="mr-6"
                    onClick={() => setMore(false)}
                  >
                    View
                  </OutlineButton>
                </Link>

                {/* <PrimaryButton
                  color="#fff"
                  className="mt-6"
                  style={{
                    padding: "10px 20px",
                  }}
                  onClick={() =>
                    setDestination({
                      lat: Number(mapInfo.locations?.[0]?.latitude),
                      lng: Number(mapInfo.locations?.[0]?.longitude),
                    })
                  }
                >
                  Get Direction
                </PrimaryButton> */}
              </div>
            </>
          )}
        </div>
        <div className="ml-2">
          <img src={starIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

const Map = ({ results }) => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: map_key,
  });
  const { theme, isDark } = useContext(themeContext);

  const [, setMap] = React.useState(null);
  const [showInfo, setShowInfo] = useState(false);
  const [mapInfo, setMapInfo] = useState({});
  const [, setCenter] = useState({});
  const [userCoords, setUserCoords] = useState({
    lat: "",
    lng: "",
  });
  const [destination, setDestination] = useState("");
  const [directionResponse, setDirectionResponse] = useState(null);
  const [travelMode] = useState("DRIVING");
  const [count, setCount] = useState(0);

  const filtered_results = results?.filter(
    (result) =>
      result?.locations?.[0]?.latitude && result?.locations?.[0]?.longitude,
  );

  // useEffect(() => {
  //   setCenter({
  //     lat: Number(filtered_results[0].locations?.[0]?.latitude),
  //     lng: Number(filtered_results[0].locations?.[0]?.longitude),
  //   });
  //   setZoom(8);
  // }, [])

  const activateInfo = (result) => {
    setMapInfo(result);
    setCenter({
      lat: Number(result.locations?.[0]?.latitude),
      lng: Number(result.locations?.[0]?.longitude),
    });
    setShowInfo(true);
  };

  const deActivateInfo = (result) => setShowInfo(false);

  // const setProps = () => {
  //   setCenter({
  //     lat: Number(filtered_results[0].locations?.[0]?.latitude),
  //     lng: Number(filtered_results[0].locations?.[0]?.longitude),
  //   });
  //   setZoom(7);
  //   // setTimeout(() => {
  //   // }, 1000);
  // }

  const directionsCallback = React.useCallback(
    (response) => {
      console.log(response?.request);

      if (response !== null) {
        if (response.status === "OK" && count === 0) {
          setDirectionResponse(response);
          setCount(count + 1);
          console.log("response: ", response);
          console.log("direction: ", directionResponse);
        } else if (response.status === "ZERO_RESULTS") {
          toastError("Can't generate direction from your location");
          console.log("response: ", response);
        } else {
          // toastError("Can't get directions");
          console.log("response: ", response);
        }
        console.log("response: ", response);
        // toastError("Can't get directions");
      } else {
        toastError("Can't get directions");
        console.log("response: ", response);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userCoords, destination],
  );

  useEffect(() => {
    setCount(0);
  }, [userCoords, destination]);

  const changeDestination = (coord) => {
    getLocation();
    deActivateInfo();
    setDestination(coord);
  };

  const getLocation = () => {
    // if (navigator.geolocation) {
    try {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.info(position);
          let lat = position.coords.latitude;
          let lng = position.coords.longitude;

          fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${map_key}`,
          )
            .then((res) => res.json())
            .then((data) =>
              setUserCoords({
                lat: Number(data.results?.[0]?.geometry?.location.lat),
                lng: Number(data.results?.[0]?.geometry?.location.lng),
              }),
            );
        },
        () => {
          toastWarning("Please ensure that you browser supports geolocation.");
        },
      );
    } catch (error) {
      console.error(error);
      toastWarning(error);
    }
    // } else {
    //   toastWarning("Please enable geolocation")
    // }
  };

  // useEffect(() => {
  //   getLocation();
  // }, []);

  const onLoad = React.useCallback(function callback(map) {
    // const bounds = new window.google.maps.LatLngBounds();
    // map.fitBounds(bounds);
    setMap(map);
    // setProps();
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={{
        lat: Number(filtered_results?.[0]?.locations?.[0]?.latitude) || 30,
        lng: Number(filtered_results?.[0]?.locations?.[0]?.longitude) || -93,
      }}
      zoom={4}
      options={{
        styles: isDark ? darkMapStyle : lightMapStyle,
      }}
      onLoad={onLoad}
      onUnmount={onUnmount}
    >
      {/* Directinos */}
      {destination !== "" && userCoords !== "" && (
        <DirectionsServiceMemo
          // required
          options={{
            destination: destination,
            origin: userCoords,
            travelMode: travelMode,
          }}
          // required
          callback={directionsCallback}
          // optional
          directionResponse={directionResponse}
          destination={destination}
          userCoords={userCoords}
          travelMode={travelMode}
          setDirectionResponse={(response) => setDirectionResponse(response)}
          onLoad={(directionsService) => {
            console.log(
              "DirectionsService onLoad directionsService: ",
              directionsService,
            );
          }}
          // optional
          onUnmount={(directionsService) => {
            console.log(
              "DirectionsService onUnmount directionsService: ",
              directionsService,
            );
          }}
        />
      )}

      {directionResponse !== null && (
        <DirectionsRenderer
          // required
          options={{
            directions: directionResponse,
          }}
          // optional
          onLoad={(directionsRenderer) => {
            console.log(
              "DirectionsRenderer onLoad directionsRenderer: ",
              directionsRenderer,
            );
          }}
          // optional
          onUnmount={(directionsRenderer) => {
            console.log(
              "DirectionsRenderer onUnmount directionsRenderer: ",
              directionsRenderer,
            );
          }}
        />
      )}
      {/* User Marker */}
      {userCoords.lng !== "" && userCoords.lat !== "" && (
        <Marker
          position={{
            lat: Number(userCoords.lat),
            lng: Number(userCoords.lng),
          }}
          // icon={markerIcon}
        />
      )}
      {/* Services Markers */}
      {filtered_results?.map((result, idx) => (
        <Marker
          key={idx}
          position={{
            lat: Number(result.locations?.[0]?.latitude),
            lng: Number(result.locations?.[0]?.longitude),
          }}
          zIndex={10}
          icon={markerIcon}
          onClick={() => activateInfo(result)}
        >
          {showInfo &&
            result.locations?.[0]?.latitude === mapInfo.locations?.[0]?.latitude &&
            result.locations?.[0]?.longitude ===
              mapInfo.locations?.[0]?.longitude && (
              <InfoWindowStyle
                position={{
                  lat: Number(mapInfo?.locations?.[0]?.latitude),
                  lng: Number(mapInfo?.locations?.[0]?.longitude),
                }}
                onCloseClick={deActivateInfo}
                bg={theme.background}
                style={{
                  background: theme.background,
                  width: "fit-content",
                }}
              >
                <MapResult
                  theme={theme}
                  mapInfo={mapInfo}
                  setDestination={(coord) => changeDestination(coord)}
                />
              </InfoWindowStyle>
            )}
        </Marker>
      ))}
    </GoogleMap>
  ) : (
    <div
      style={containerStyle}
      className="animate-pulse rounded-md bg-gray-300 opacity-75 mb-4"
    />
  );
};

export default Map;
